import React from "react";
import { useTranslation } from "react-i18next";
import {
  FaFacebookF,
  FaInstagram,
  FaMapMarker,
  FaPhone, FaTwitter,
  FaBuilding
} from "react-icons/fa";
import Logo from "../../images/world-logo.png";
import ALink from "../common/ALink";
import "./Footer.css";

export default function Footer() {
  const { t } = useTranslation();
  return (
    <footer className="footer__distributed">
      <div className="footer__container">
        <div className="footer__left">
          <ALink to="/" className="footer__logo-link">
            <img src={Logo} alt="world-logo" id="logo" className="footer__logo-img" />
          </ALink>
        </div>

        <div className="footer__center">
        <div className="footer__company-info-row">
            <div className="footer__company-info-icon">
              <FaBuilding size={16} />
            </div>
            <p>{t("footer__company__name")} </p>
          </div>

          <div className="footer__company-info-row">
            <div className="footer__company-info-icon">
              <FaMapMarker size={16} />
            </div>
            <p>
              <span>〒500-8856</span>
              {t("footer__address")}
            </p>
          </div>

          <div className="footer__company-info-row">
            <div className="footer__company-info-icon">
              <FaPhone size={16} />
            </div>
            <p>TEL 0120-74-0477</p>
          </div>
        </div>

        <div className="footer__right">
          <div className="footer__icons">
            <a
              href="https://www.facebook.com/worldworksgifu"
              target="_blank"
              rel="noreferrer"
              className="footer__icon-fb"
              // rel="noopener"
            >
              <FaFacebookF className="footer__icon" />
            </a>
            <a
              href="https://twitter.com/World_rec_Br"
              target="_blank"
              rel="noreferrer"
              className="footer__icon-tw"
              // rel="noopener"
            >
              <FaTwitter className="footer__icon" />
            </a>
            <a
              href="https://www.instagram.com/world_gifu_br/"
              target="_blank"
              className="footer__icon-ins"
              rel="noreferrer"
              // rel="noopener"
            >
              <FaInstagram className="footer__icon" />
            </a>
          </div>
          <p className="footer__copyright">
            Copyright © 2020 by{" "}
            <a
              className="footer__company__link"
              href="https://world-works.co.jp/corporate/"
            >
              株式会社ワールド
            </a>
            . All rights reserved
          </p>
        </div>
      </div>
    </footer>
  );
}
