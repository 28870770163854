import React from "react";
import "./MenuHeader.css";
import Logo from "../../images/world-logo.png";
import ALink from "../common/ALink";
import LanguageSwitcher from "../common/LanguageSwitcher";

export default function MenuHeader() {
  return (
    <header className="menu-header">
      <div className="menu-header__content-left">
        <ALink to="/" className="menu-header__logo">
          <img src={Logo} alt="" id="logo" />
        </ALink>
      </div>

      <div className="menu-header__content-right">
        <div className="menu-header__content-item">
          <div className="menu-header__Language">
            <LanguageSwitcher />
          </div>
        </div>
      </div>
    </header>
  );
}
